import React, { useEffect } from "react";
const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
  isMarketPlace,
  isLeastTimer,
  showExtraText,
  showBiddingStart,
  showProgressBarsec,
  card,
}: any) => {
  const eextraText = showExtraText
    ? "Until Lots Begins Closing"
    : showBiddingStart
    ? "Until Bidding Starts"
    : "";

  useEffect(() => {
    // console.log("hours", hours);
    // console.log("minutes", minutes);
    if (hours == 0 && minutes == 0) {
      try {
        showProgressBarsec(true, seconds);
      } catch (error) {
        console.log("errorerror", error);
      }
    } else {
      showProgressBarsec(false, seconds);
    }
  }, [hours, minutes]);
  // const leastTimeType = () => {
  //   if (minutes <= 0) {
  //     return `${seconds} s`;
  //   } else if (hours <= 0) {
  //     return `${minutes} m`;
  //   } else if (days <= 0) {
  //     return `${hours} h`;
  //   } else {
  //     return `${days} ${days > 1 ? "days" : "day"} ${eextraText}`;
  //   }
  // };
  return (
    <>
      {days < 1 ? (
        <>
          <span className={`timer-low-sec-count${hours}`}>
            {`${(hours < 10 ? "0" : "") + hours}:${
              (minutes < 10 ? "0" : "") + minutes
            }:${(seconds < 10 ? "0" : "") + seconds}`}
          </span>{" "}
          {`${eextraText}`}{" "}
        </>
      ) : (
        `${days} ${days > 1 ? "Days" : "Day"} ${eextraText}`
      )}
    </>
  );
};
export const InspectionCounter = ({ days, hours, minutes, seconds }: any) => {
  return (
    <>
      {`${days && days + " d : "}${hours} h : ${minutes} m${
        seconds && " : " + seconds + " s"
      }`}
    </>
  );
};
export default ShowCounter;
