import React from "react";

import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import SliderOne from "./../../assets/image/slide1.jpg";
import SliderTwo from "./../../assets/image/slide2.jpg";
import "./css/UpcomingActionSlider.css";
import ItemCard from "./ItemCard";
import { isMobile } from "react-device-detect";
import MobileView from "./MobileView";

class UpcomingActionSlider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      upCommingAuctions: [],
    };
  }

  componentDidMount() {
    // this.getAllSiteManagers();
  }
  // getAllSiteManagers = async()=>{
  //   const resp = await getAllAuctions();
  //   this.setState({upCommingAuctions:resp?.upCommingAuctions});
  // }
  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 1920, min: 1400 },
        items: 3,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 1399, min: 1100 },
        items: 3,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1.1,
        slidesToSlide: 1,
      },
    };

    const upCommingAuctions = this.props?.upCommingAuctions;
    return (
      <>
        <div className="custom_action_slider">
          {isMobile ? (
            <MobileView upCommingAuctions={upCommingAuctions} />
          ) : (
            <Carousel
              responsive={responsive}
              removeArrowOnDeviceType={["mobile"]}
            >
              {(upCommingAuctions || []).map((item: any, index: any) => {
                return <ItemCard item={item} handleSucessafterRegister={this.props.handleSucessafterRegister} />;
              })}
            </Carousel>
          )}
        </div>
      </>
    );
  }
}
export default UpcomingActionSlider;
