import React from "react";

import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import noImage from "../../assets/image/noImage.jpg";
import { isMobile } from "react-device-detect";
import moment from "moment";
import CardItem from "./CardItem";
import MobileView from "./MobileView";
class ActiveActionsSlider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      upCommingAuctions: [],
    };
  }
  componentDidMount() {}

  render() {
    const responsive = {
      // desktop: {
      //   breakpoint: { max: 1920, min: 1400 },
      //   items: 3,
      //   slidesToSlide: 1,
      // },
      tablet: {
        breakpoint: { max: 1399, min: 1100 },
        items: 3,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 1920, min: 1400 },
        items: 3,
        slidesToSlide: 1,
      },
    };

    const { onGoingAuctions } = this.props?.AllCollections;
    return (
      <>
        {isMobile ? (
          <MobileView onGoingAuctions={onGoingAuctions} />
        ) : (
          <div className="custom_action_slider">
            <Carousel
              responsive={responsive}
              // removeArrowOnDeviceType={["mobile"]}
            >
              {(onGoingAuctions || []).map((item: any, index: any) => {
                return <CardItem item={item} handleSucessafterRegister={this.props.handleSucessafterRegister}  />;
              })}
            </Carousel>
          </div>
        )}
      </>
    );
  }
}
export default ActiveActionsSlider;
