import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";

import Calend from "../../assets/image/calend.png";
import Share from "../../assets/image/share.png";
import Print from "../../assets/image/print.png";
import Download from "../../assets/image/download.png";
import ViewMore from "../../assets/image/view_more.png";
import { ReactComponent as InfoIcon } from "../../assets/image/infoIcon.svg";
import ShareModal from "../../components/ShareModal/ShareModal";
import { DOMAIN_URL } from "./../../config";
// import moment from 'moment';
import moment from "moment-timezone";
import { LOGINID } from "./../../Scenes/common";
import TopBannerTime from "./../../pages/collectionDetails/TopBannerTime";
import "./css/BrowseListTopBanner.css";
import { ENV_SHOT_CODE } from "./../../config";
import CountdownTimer from "./../Counter/CountdownTimer";
import {
  convertUTCToLocaltime,
  convertUTCToLMilleSec,
  convertLMilleSec,
} from "./../../Scenes/common";

class BrowseListTopBanner extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      bannerListOpen: false,
      shareOpen: false,
    };
  }
  componentDidMount = () => {};

  addToCalendar = () => {
    console.log("this.addToCalendar");
  };
  handleShareAction = (flag: any) => {
    console.log("flag", flag);
    this.setState({ shareOpen: flag });
  };
  render() {
    const {
      currenciesList,
      collectionData,
      registertoBid,
      showLiveActionBtn,
      biddingShowText,
      biddingShowNo,
      gotoAuctionCalenderPage,
      gotoAuctionResultsPage,
      liveAuctionPage,
      isPastAuction,
    } = this.props;
    let bannerTimeZone = this.props?.timezonesList?.find(
      (i: any) => collectionData?.timeZoneId == i?.id
    )?.abbrivation;

    const gmtDateTime = moment.utc(
      collectionData?.startOnUtc,
      "YYYY-MM-DD h:mm:ss a"
    );
    const local_date = gmtDateTime.local().format("MMMM D, YYYY");
    const local_time = gmtDateTime.local().format("h:mm A");
    const local_timezone = moment(collectionData?.startOnUtc)
      .tz(moment.tz.guess())
      .format("z");
    let endtimeLink = "";
    if (collectionData?.endOnUtc && collectionData?.endOnUtc != "") {
      const endtimeLink1 = moment.utc(
        collectionData?.endOnUtc,
        "YYYY-MM-DD h:mm:ss a"
      );
      endtimeLink = `${endtimeLink1.local().format("YYYYMMDD")}T${endtimeLink1
        .local()
        .format("HHMMSS")}`;
    }
    const timesLink = `${gmtDateTime.local().format("YYYYMMDD")}T${gmtDateTime
      .local()
      .format("HHMMSS")}/${endtimeLink}`;
    const { bannerListOpen, shareOpen } = this.state;

    // console.log("shareOpen", shareOpen);
    const lotPageUrl = `${DOMAIN_URL}collectionDetails/${collectionData?.id}`;
    console.log("top banner top banner");
    const collSLocalTim = convertUTCToLMilleSec(collectionData?.startOnUtc);
    const currentTime = convertLMilleSec();

    return (
      <>
        <ShareModal
          show={shareOpen}
          handleClose={() => this.handleShareAction(false)}
          url={lotPageUrl}
          marketplaceId={collectionData?.id}
          title={collectionData?.title}
          onCopyClick={() => {
            navigator.clipboard.writeText(lotPageUrl);
            // setIsCopied(true);
            // setTimeout(() => {
            //   setIsCopied(false);
            // }, 5000);
          }}
        />

        <div className="brows_banner_content">
          <Container>
            <Row className="inner_banner_content">
              <Col lg={9}>
                <div className="brows_banner_left_content">
                  <div
                    className="brows_banner_back_btn d-inline-flex align-items-center"
                    onClick={() => {
                      isPastAuction
                        ? gotoAuctionResultsPage()
                        : gotoAuctionCalenderPage();
                    }}
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M7 13L1 7L7 1"
                        stroke="#111111"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {isPastAuction ? "Auction Results" : "Auction Calendar"}
                  </div>
                  <div className="auctions_details">
                    {collectionData?.title && (
                      <ul className="d-flex p-0">
                        <li className="position-relative">
                          {!isPastAuction
                            ? collectionData?.auctionType == 1
                              ? "Online Only"
                              : "Live"
                            : "Past"}{" "}
                          Auction
                        </li>
                        <li className="strong position-relative">
                          {collectionData?.auctionType == "1" ? (
                            <>
                              {!collectionData?.onlineStartDate
                                ? moment(
                                    collectionData?.onlineStartDate
                                  ).format("MMMM D, YYYY")
                                : moment(
                                    collectionData?.onlineStartDate
                                  ).format("MMMM D ")}
                              {collectionData?.onlineEndDate && (
                                <>
                                  {` `}-{" "}
                                  {moment(collectionData?.onlineEndDate).format(
                                    "MMMM D, YYYY"
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {moment(collectionData?.liveStartDate).format(
                                "MMMM D, YYYY"
                              )}{" "}
                            </>
                          )}
                        </li>
                        {/* <li className="position-relative">
                          {collectionData?.auctionType == "1" ? (
                            <>
                              {moment(
                                collectionData?.onlineStartTime,
                                "HHmmss"
                              ).format("h:mm A")}{" "}
                              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}{" "}
                            </>
                          ) : (
                            <>
                              {moment(
                                collectionData?.liveStartTime,
                                "HHmmss"
                              ).format("h:mm A")}{" "}
                              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}{" "}
                            </>
                          )}{" "}
                          <InfoIcon className="bannerInfoIcon" />
                          <div className="hoverInfo">
                            <p className="yourLocalTime">Your Local Time</p>
                            <p> {local_date} </p>
                            <p>
                              {" "}
                              {local_time} {local_timezone}{" "}
                            </p>
                          </div>
                        </li> */}
                      </ul>
                    )}
                    <h3>{collectionData?.title}</h3>
                    <div className="coll-top-banner-time-sec">
                      <TopBannerTime
                        collectionData={collectionData}
                        bannerTimeZone={bannerTimeZone}
                        formPage="collection-page"
                      />
                    </div>

                    {collectionData?.title && currentTime < collSLocalTim && (
                      <>
                        {collectionData?.registredToCollection ? (
                          <Button
                            disabled
                            className="auction_btn_banner registeredbtns d-inline-block text-decoration-none registered-button"
                          >
                            REGISTERED
                          </Button>
                        ) : (
                          <>
                            <Button
                              onClick={registertoBid}
                              className="auction_btn_banner registeredbtns onHoverRegister d-inline-block text-decoration-none"
                            >
                              REGISTER TO BID
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    {collectionData?.auctionType === 2 &&
                      currentTime > collSLocalTim && (
                        <>
                          <Button
                            onClick={liveAuctionPage}
                            className="auction_btn_banner registeredbtns onHoverRegister d-inline-block text-decoration-none"
                          >
                            View Live Auction
                          </Button>
                        </>
                      )}
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div
                  className={`brows_banner_right_content show-srch ${
                    bannerListOpen ? "active" : ""
                  }`}
                >
                  <div
                    className="mobile_open_browse_list_background"
                    onClick={() => this.setState({ bannerListOpen: false })}
                  ></div>
                  <div
                    className="mobile_open_browse_list d-block d-lg-none"
                    onClick={() => this.setState({ bannerListOpen: false })}
                  ></div>
                  <ul className="m-0 p-0">
                    <li>
                      <a
                        href={`https://www.google.com/calendar/event?action=TEMPLATE&text=${collectionData?.title}&dates=${timesLink}&details`}
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <Button
                          onClick={() => {
                            this.addToCalendar();
                          }}
                          className="browse_banner_list browse_banner_list_AddC text-decoration-none d-flex align-items-center"
                        >
                          <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                            <img src={Calend} className="position-absolute" />
                          </span>
                          <h5 className="mb-0">Add to Calendar</h5>
                        </Button>
                      </a>
                    </li>
                    <li>
                      <Link
                        to={`/collectionDetails/${collectionData?.id}`}
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                          <img
                            onClick={() => {
                              this.handleShareAction(true);
                            }}
                            src={Share}
                            className="icon_share position-absolute"
                          />
                        </span>
                        <h5
                          onClick={() => {
                            this.handleShareAction(true);
                          }}
                          className="mb-0"
                        >
                          Share
                        </h5>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/collectionDataPrint/${collectionData?.id}`}
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                          <img
                            src={Print}
                            className="icon_share position-absolute"
                          />
                        </span>
                        <h5 className="mb-0">Print</h5>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/collectionDataDownload/${collectionData?.id}`}
                        target="_blank"
                        className="browse_banner_list text-decoration-none d-flex align-items-center"
                      >
                        <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                          <img
                            src={Download}
                            className="icon_share position-absolute"
                          />
                        </span>
                        <h5 className="mb-0">Download Auction Catalog (PDF)</h5>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
export default BrowseListTopBanner;
