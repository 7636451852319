import React, { useState } from "react";

import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import MoreCollectionImg from "./../../assets/image/slide1.jpg";
import BidBlack from "../../assets/image/bid-black.png";
import BidWhite from "../../assets/image/bid-white.png";
import { Route, Switch, withRouter } from "react-router-dom";
import "./css/MoreCollectionColum.css";

interface Props {
  collectionItems: any;
  currenciesList: any;
  collectionData: any;
  gotoBigPage: any;
}

export const MoreCollectionColum: React.FC<Props> = (Props) => {
  const { collectionItems, currenciesList, collectionData, gotoBigPage } =
    Props;
  let currencyName = currenciesList?.find(
    (i: any) => collectionData?.currency == i?.id
  )?.currencyCode;

  const [placeBiHover, setPlaceBidHover] = useState<number | null>(null);

  const getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i: any) => i.displayOrder == 1
      )?.vurtualPath;
      return itemURRL;
    }
  };
  // const gotoBigPage = (num:any)=>{
  //   Props.history.push(`/lotDetails/${num}`)
  // }

  return (
    <>
      {(collectionItems || []).length > 0 && (
        <div className="more_collection_colum more_collection_colum_lot_details">
          <div className="more_from">
            <div className="more_from_title_sec d-flex align-items-center">
              <div className="d-block mr-auto collection-title-devision">
                <h6>More from</h6>
                <h3 className="title_1">{collectionData?.title}</h3>
              </div>
              <Link
                to={`/collectionDetails/${collectionData?.id}`}
                className="more-collect-view-all-button"
              >
                View All
              </Link>
            </div>
            <Row className="trending_lots_slider_cont">
              {collectionItems.slice(0, 4).map((item: any, index: any) => {
                return (
                  <Col
                    lg={3}
                    md={6}
                    className="lots_slider_cont pb-lg-0 pb-5"
                    key={item.id}
                  >
                    <div className="lot_number d-inline-block">
                      Lot {item.sno}
                    </div>
                    <div
                      onClick={() => {
                        gotoBigPage(`/lotDetails/${item?.id}`, item?.id);
                      }}
                      className="lots_slider_img more-collection-border"
                    >
                      <img src={getmainImage(item)} className="w-100" />
                    </div>
                    <div className="lots_slider_cont_details">
                      <h4>{item.title}</h4>
                      <div className="price_list_btn d-flex justify-content-between">
                        <div className="lots_cost">
                          <span className="d-block">Estimate</span>
                          <span className="d-block strong">
                            {currencyName}{" "}
                            {item.estimatedPrice?.toLocaleString()}
                            {item.estimatedPriceTo && (
                              <>
                                {` - ${currencyName} ${item.estimatedPriceTo?.toLocaleString()}`}
                              </>
                            )}
                          </span>
                        </div>
                        <div className="btn_lots">
                          <Button
                            onClick={() => {
                              gotoBigPage(`/lotDetails/${item?.id}`, item?.id);
                            }}
                            onMouseEnter={() => {
                              setPlaceBidHover(index);
                            }}
                            onMouseLeave={() => {
                              setPlaceBidHover(null);
                            }}
                            className="lots_btn_same d-inline-flex align-items-center justify-content-center text-decoration-none"
                          >
                            <img
                              src={placeBiHover === index ? BidWhite : BidBlack}
                              className="bid_icon mr-1"
                            />
                            BID
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <div className="d-block d-lg-none">
              {/* <Link
                to={`/collectionDetails/${collectionData?.id}`}
                className="view_all_from d-block text-center text-decoration-none mx-auto"
              >
                View All
              </Link> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MoreCollectionColum;
