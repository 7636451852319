import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TheWordSlider from "../../components/TheWordSlider";
import LotsSlider from "../../components/LotsSlider";
import ActionPage from "../ActionsPage";
import ComingSoonPage2 from "./../ComingSoonPage2/ComingSoonPage2";
import { STATIC_GATE_CODE } from "./../../library/constants";
import { getGateCode } from "./../../Scenes/common";
import Banner from "./../../components/Banner";
import ContentLoaderSection from "./../../components/ContentLoaderSection/ContentLoaderSection";
import { getAllSiteManagersCall } from "./../../library/Requests/SiteManager";
import {
  getAllActiveAuctions,
  getAllAuctions,
  getAllCategories,
  getAllUpcomingAuctions,
} from "./../../library/Requests";

const Home = (props: any) => {
  const [allData, setAllData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [AllCollections, setAllCollections]: any = useState({});
  const [upCommingAuctions, setUpCommingAuctions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllSiteManagers();
  }, []);

  const getAllSiteManagers = async () => {
    setIsLoading(true);
    const resp = await getAllSiteManagersCall("1"); //.splice(1)
    setAllData(resp?.data);
    const cate = await getAllCategories();

    setAllCategories(cate?.data);

    setIsLoading(false);

    handleSucessafterRegister();
  };

  const handleSucessafterRegister = async () => {
    setIsLoader(true);

    let userInfo: any = localStorage.getItem("customerInfoDetails");

    const userIn = JSON.parse(userInfo);
    const body = {
      page: 1,
      pageSize: 10,
      getOnlyTotalCount: false,
      sortColumn: "StartOnUtc",
      sortDirection: "desc",
      customerId: userIn?.id,
    };
    const resp1 = await getAllActiveAuctions(body);
    const resp2 = await getAllUpcomingAuctions(body);

    console.log(resp1, resp2, "getAllActiveAuctions");
    setAllCollections(resp1);
    setUpCommingAuctions(resp2?.upCommingAuctions);
    setIsLoader(false);
  };

  return (
    <>
      {STATIC_GATE_CODE === getGateCode() ? (
        <>
          {isLoading ? (
            <>
              <div className="TopSectionLoading">
                <ContentLoaderSection section="topbanner" />
              </div>
              <div className="HomePageCollectionsLoading">
                <ContentLoaderSection section="topbanner1" />
              </div>
            </>
          ) : (
            <Banner bannerList={allData} />
          )}
          {/* <TopBannerSlider /> */}
          {!isLoader ? (
            <div className="custom_action_slider_section">
              <Container>
                <ActionPage
                  upCommingAuctions={upCommingAuctions}
                  AllCollections={AllCollections}
                  handleSucessafterRegister={handleSucessafterRegister}
                />
              </Container>
            </div>
          ) : (
            <div className="HomePageCollectionsLoading">
              <ContentLoaderSection section="topbanner1" />
            </div>
          )}
          <div className="word_slider_section">
            <Container>
              <div className="custom_slider_heading_filter d-flex align-items-center">
                <h3 className="mb-0 mr-auto position-relative pl-3">
                  the Word
                </h3>
              </div>
            </Container>
            <div className="word_slider_bg_section">
              <Container>
                <TheWordSlider />
              </Container>
            </div>
          </div>
          <div className="custom_lots_slider_section">
            {/* <Container> */}

            <LotsSlider />
            {/* </Container> */}
          </div>
        </>
      ) : (
        <>
          <ComingSoonPage2 />{" "}
        </>
      )}
    </>
  );
};
export default Home;
